import React from 'react';
import Najran from "./NajranBG.jpg"
import Nav from "./Nav"

function App() {
  return (
    <div className="App">

      <div dir='rtl' style={{ float: 'right', display: 'flex column', alignContent: 'left', width: '100%', position: 'absolute', color: 'white', fontSize: '400%' }}>
        {/* <h1 dir='rlt' style={{ opacity: '70%', alignSelf: 'left' }}>حسين بن مشرف</h1>

        <h1 dir='rlt' style={{ opacity: '70%', alignSelf: 'left' }}>سيرة ذاتية</h1> */}
      </div>
      <img src={Najran} style={{ width: '100%', margin: 'auto' }} alt="najran" />


      <Nav />

      <div style={{ textAlign: 'center', padding: '5%', color: 'grey' }}>
        <div></div>
        <div>rami@bayanatnow.com</div>
      </div>
    </div>
  );
}



export default App;
