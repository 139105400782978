import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Intro from "./Intro"
import Auther from "./Auther"
import One from "./One"
import Two from "./Two"
import Three from "./Three"
import Final from "./Final"
import Thanks from "./Thanks"
import Poem from "./Poem"





function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ScrollableTabsButtonAuto() {
    const classes = useStyles();
    const [value, setValue] = React.useState(7);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="black">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"

                >
                    <Tab style={{ fontSize: '20px' }} label="كلمة الشيخ / حسين بن مشرف" {...a11yProps(0)} />
                    <Tab style={{ fontSize: '20px' }} label="قصائد" {...a11yProps(1)} />
                    <Tab style={{ fontSize: '20px' }} label="الخاتمة" {...a11yProps(2)} />

                    <Tab style={{ fontSize: '20px' }} label="الفصل الثالث" {...a11yProps(3)} />
                    <Tab style={{ fontSize: '20px' }} label="الفصل الثاني" {...a11yProps(4)} />
                    <Tab style={{ fontSize: '20px' }} label="الفصل الأول" {...a11yProps(5)} />
                    <Tab style={{ fontSize: '20px' }} label="كلمة الكاتب" {...a11yProps(6)} />
                    <Tab style={{ fontSize: '20px' }} label="مقدمة" {...a11yProps(7)} />
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
                <Thanks />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Poem />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Final />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Three />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Two />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <One />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <Auther />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <Intro />
            </TabPanel>
        </div>
    );
}