import React from 'react'
import style1 from "./style"

export default function One() {
    return (
        <div>
            <p style={style1.st} dir='rtl'>

                في هذا الفصل سأتحدث عن نسبه ومولده ثم نشأته ومهنته
                .. فاسمه بالكامل هو: حسين بن أحمد بن عبدالله بن رجب بن عبدالله بن سعد بن عبدالله بن أحمد بن محمد بن علي آل مشرف.. ولد عام 1357 للهجرة بمدينة نجران القديمة أو نجران البلد كما يسمونها الآن.. تزوج تسع من النساء أنجب من أربع منهن.. وله من الأبناء والبنات ثمانية وعشرون.. خمسة عشر ولدا هم على الترتيب محمد ،عبدالكريم، أحمد ، محسن ، حمد ، ماجد ، منصور ،سليمان ،صالح ،يحيى ،حسام ، مشرف، ذيبان ، أدهم ،وأخيرا بنيان.

                نشأ حسين بن رجب ال مشرف في فترة من الزمن كانت العصبية القبلية هى السائدة في نجران حيث كانت القبلية سائدة والفرد القبيلي آنذاك هو كل شئ فى هذه المنطقة، فهو المحارب المقدام وهو الفارس الشجاع وغيره الجبان المهان وهو الصادق وغيره من البشر كذاب.. وهو الجواد الكريم الغيور على أهله وعرضه.. ووسط هذه البيئة ولد حسين بن مشرف وسط هذه النزعات وهذه الهالة من الأفكار والاعتقادات القبلية التي تسيطر على عقول الناس في ذلك الزمان والمكان.. في حين لم يكن انذاك قد تعرف على نسبه الحقيقي ولكن كان بداخله إحساس قوي بأن اجداده لهم شأن عظيم ولكن الدنيا وظروف الحروب انذاك شتتت شملهم وقللت من شأنهم.. وهذا الإحساس كان هو بوصلته التي توجهه نحو أعمال وأفعال الإنسان القبيلي الأصيل دون أن يتيقن آنذاك انه من نسل أقدم القبائل التي قطنت نجران وأعرقها نسبا.. وما ان اشتد عوده حتي اخذ مكانته بين اقرانه من ابناء القبائل الأخرى واصبح يشار اليه بالبنان في كل مناحي الحياة.

                نشأ هذا الرجل في بيت تجاري أبا عن جد وترعرع فى كنف أبيه وبين إخوانه مثله فى ذلك مثل أقرانه من أبناء جلدته.. ثم بعد أن اشتد عوده وقويت بنيته اشتغل بالتجارة وبرع فيها وتفوق على كل من سبقوه حتى أصبح له أسلوبه ونمطه الخاص فى إدارة شؤونه التجارية.. هذا الأسلوب يصعب على الشخص العادي فهمه دون التعمق فى شخصية هذا الرجل والسير على دربه مسافات طويلة.. مما اكسبه المزيد من العلاقات المتميزة والمواقف الخالدة مع كافة ابناء المنطقة من مختلف الاجيال.
</p>

        </div>
    )
}
