import React from 'react'
import style1 from "./style"

import grand1 from "./grand1.JPG"
import grand2 from "./grand2.JPG"




export default function Thanks() {
    return (
        <div style={{ textAlign: 'right' }}>

            <img dir='rtl' src={grand1} style={{ left: '0px', height: "20vh", padding: '15px', textAlign: 'right' }} alt="حسين بن مشرف 1" />
            <img dir='rtl' src={grand2} style={{ left: '0px', height: "20vh", padding: '15px', textAlign: 'right' }} alt="حسين بن مشرف 2" />

            <p style={style1.st} dir='rtl'>
                الحمد لله رب العالمين والصلاة والسلام على خاتم المرسلين سيدنا محمد بن عبدالله صلي الله عليه وعلى آل بيته الطيبين الطاهرين وعلى أصحابه والتابعين ومن تبعهم بإحسان إلي يوم الدين.. أما بعد:
اطلعت بسرور على ما كتبه الأخ عبدالرؤوف بحقي .. وسعدت بذلك ايما سعادة.. ونشكر له اهتمامه بهذا الأمر .. مقدرا المجهود الذي بذله من اجل اخراج هذا النبذة المختصرة بهذه الصورة الشيقة .. ونتمنى له التوفيق والسداد ولا مانع لدينا من عرضه على أي فرد للاطلاع عليه أو نشره في أي مكان .. هذا وبالله التوفيق. </p>


        </div>
    )
}
